<template>
  <div>
    <DxTabPanel
      height="100%"
      :show-nav-buttons="true"
      :repaint-changes-only="true"
      :animation-enabled="true"
    >
      <DxItem title="Pis/Cofins">
        <template #default>
          <PisCofins />
        </template>
      </DxItem>
      <DxItem title="Totais Por CST">
        <template #default>
          <TotaisPorCst />
        </template>
      </DxItem>
      <DxItem title="NCM x CST Pis/Cofins">
        <template #default>
          <NcmxCstPisCofins />
        </template>
      </DxItem>
    </DxTabPanel>
  </div>
</template>
<script lang="ts" src="./index.ts"></script>